<template>
  <div id="guide">
    <div class="admin_main_block">
      <div class="user_temp_title">{{ article[0].title }}</div>
      <div class="admin_table_main" v-html="article[0].content">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      article: [
        {
          title: "",
          conent: "",
        },
      ],
    };
  },
  created() {
    this.getArticleList();
    this.IsPC();
  },
  mounted() {},
  watch: {},
  methods: {
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        this.$router.push("/m_guide");
      }
    },

    getArticleList() {
      this.$request.post({
        url: "/ReportApi/business/article/getArticleInfo",
        params: {
          pageNo: 1,
          pageSize: 1,
          type: 6,
          isConent: 1,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          if (res.data.length > 0) {
            this.article = res.data;
          }

        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#guide {
  .admin_main_block {
    border-radius: 5px;
    min-height: 855px;
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    ol {
      list-style: none;
    }
    .user_temp_title {
      margin: auto;
      padding-left: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      height: 25px;
      margin-bottom: 20px;
      font-weight: bold;
    }
  }
}
</style>
